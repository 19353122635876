<template>
  <v-container class="text-left">
    <v-row
      ><v-col>
        <div class="text-h2">Important Information About Cookies</div>
        <div class="text-h5">Dear User,</div>
        <br />
        <div class="text-h6">
          We want to provide you with the best possible experience when using
          our web app. To achieve this, we use cookies, which are small files
          stored on your device. Cookies help us improve your interaction with
          the app, personalize your settings, and enhance security.
        </div>
        <br />
        <div class="text-h6">
          Please note that cookies are necessary for the proper functioning of
          the app. However, we respect your choices. If you decline cookies,
          certain features may be limited, and your experience might not be as
          smooth.
        </div>
        <br />
        <div class="text-h6">
          If you have any questions or need assistance, please feel free to
          contact our support team.
        </div>
        <br />
        <div class="text-h6">Thank you for using our web app.</div>
        <br />
        <div class="text-h6">
          To revoke your cookie preference choice click below.
        </div>
        <br />
        <v-btn color="success" @click="resetCookies"
          >🍪 Reset cookie preferences 🍪</v-btn
        >
      </v-col></v-row
    >
  </v-container>
</template>

<script>
export default {
  methods: {
    resetCookies() {
      this.$store.dispatch('user/resetCookies')
    },
  },
}
</script>

<style lang="scss" scoped></style>
